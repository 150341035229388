var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c(
        "el-form",
        {
          ref: "credentials",
          attrs: {
            "label-position": _vm.labelPosition,
            rules: _vm.rules,
            "label-width": "100px",
            model: _vm.credentials,
          },
        },
        [
          _c("div", { staticClass: "title-login" }, [_vm._v("ĐĂNG NHẬP")]),
          _c(
            "el-form-item",
            { attrs: { label: "Tên khoản", prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-phone-outline",
                  "auto-complete": "",
                  placeholder: "Nhập số điện thoại",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submitForm("credentials")
                  },
                },
                model: {
                  value: _vm.credentials.username,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.credentials,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "credentials.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mật khẩu", prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  "prefix-icon": "el-icon-lock",
                  placeholder: "Nhập mật khẩu",
                  "show-password": "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submitForm("credentials")
                  },
                },
                model: {
                  value: _vm.credentials.password,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.credentials,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "credentials.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "appType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.credentials.appType,
                    callback: function ($$v) {
                      _vm.$set(_vm.credentials, "appType", $$v)
                    },
                    expression: "credentials.appType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "plus" } }, [
                    _vm._v("Nhà trường"),
                  ]),
                  _c("el-radio", { attrs: { label: "teacher" } }, [
                    _vm._v("Giáo viên"),
                  ]),
                  _c("el-radio", { attrs: { label: "parent" } }, [
                    _vm._v("Phụ huynh"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "error",
              style: {
                visibility: _vm.showBadCredential ? "visible" : "hidden",
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.loginError) + "\n    ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "primary", loading: _vm.loadingButton, round: "" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("credentials")
                },
              },
            },
            [_vm._v("Đăng nhập")]
          ),
          _c("div", { staticClass: "note" }, [
            _c("span", [_vm._v("Liên hệ với nhà trường khi quên mật khẩu")]),
            _c("hr"),
            _c("span", [_vm._v("Trẻ em hôm nay, thế giới ngày mai!")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }